
import { Decoration1, ActiveRingChart } from '@jiaminghi/data-view-react'

import LabelTag from './LabelTag'

import './CenterCmp.less'

import React, { useEffect, useState } from 'react'


async function fetchAllProjectWithIssueCounts() {
  const auth = 'Basic ' + btoa('linus.zhang@qdu.edu.cn:Z249615wjy!!'); // 使用您的JIRA用户名和密码
  try {
    const projectsResponse = await fetch('https://work.wearlab.tech/rest/api/2/project', {
      method: 'GET',
      headers: {
        Authorization: auth,
        'Content-Type': 'application/json',
      },
    });
    const projects = await projectsResponse.json();

    // 对每个项目并发查询Issue数量
    const issuesCountPromises = projects.map(async (project) => {
      try {
        const issuesResponse = await fetch(`https://work.wearlab.tech/rest/api/2/search?jql=project=${project.key} AND status not in (Closed,Done,完成)`, {
          method: 'GET',
          headers: {
            Authorization: auth,
            'Content-Type': 'application/json',
          },
        });
        const issuesData = await issuesResponse.json();
        return { key: project.key, issueCount: issuesData.total }; // 返回包含项目键和Issue数量的对象
      } catch (error) {
        console.error(`Failed to fetch issues for project ${project.key}`, error);
        return { key: project.key, issueCount: 0 }; // 错误处理，返回0作为Issue数量
      }
    });

    // 等待所有Issue数量查询完成
    const issuesCounts = await Promise.all(issuesCountPromises)

    // 将Issue数量整合到项目对象中
    const filteredProjects = projects.filter(project => project.name !== "RoboMaster");

    const projectsWithIssueCounts = filteredProjects.map(project => {
      const projectIssueInfo = issuesCounts.find(p => p.key === project.key) || { issueCount: 0 };
      return { ...project, issueCount: projectIssueInfo.issueCount }
    })

    console.log(projectsWithIssueCounts)
    return projectsWithIssueCounts
  } catch (error) {
    console.error('Failed to fetch JIRA projects', error);
    return [];
  }
}



async function fetchAllProject() {
  const auth = 'Basic ' + btoa('linus.zhang@qdu.edu.cn:Z249615wjy!!'); // 使用您的JIRA用户名和密码
  try {
    const response = await fetch('https://work.wearlab.tech/rest/api/2/project', {
      method: 'GET',
      headers: {
        Authorization: auth,
        'Content-Type': 'application/json',
      },
    });
    const projects = await response.json();
    console.log(projects);

    // 使用map将项目键转换为promise数组
    const projectIssuesPromises = projects.map(async (project) => {
      try {
        const response = await fetch(`https://work.wearlab.tech/rest/api/2/search?jql=project=${project.key} AND status not in (Closed,Done,完成)`, {
          method: 'GET',
          headers: {
            Authorization: auth,
            'Content-Type': 'application/json',
          },
        });
        return await response.json(); // 直接返回promise结果
      } catch (error) {
        console.error(`Failed to fetch JIRA data for project ${project.key}`, error);
        return null; // 在出错时返回null或合适的默认值
      }
    });

    // 并发执行所有请求并等待它们全部完成
    const projectIssues = await Promise.all(projectIssuesPromises);
    console.log(projectIssues);

    return projects; // 根据需要返回projects或其他数据
  } catch (error) {
    console.error('Failed to fetch JIRA projects', error);
    return [];
  }
}


export default () => {
  const [config,setConfig] = useState(
    {
      data: [
        // 初始数据可以保留或者清空
      ],
      color: ['#00baff', '#3de7c9', '#fff', '#ffc530', '#469f4b'],
      lineWidth: 30,
      radius: '55%',
      activeRadius: '60%',
      showOriginValue: true,
      activeTimeGap: 500
    }
  )
  const [labelConfig, setLabelConfig] = useState({ data: [] })
  const [jiraData, setJiraData] = useState([]);
  const [leftLabels, setLeftLabels] = useState([]);
  const [rightLabels, setRightLabels] = useState([]);
  const [totalNum, setTotalNum] = useState(0)

  useEffect(() => {
    const initData = async () => {
      const data = await fetchAllProjectWithIssueCounts();
      const dt = data.map(da => da.name);
      const newData = data.map(project => ({
        name: project.name, // 确保这里的name对应项目名称
        value: project.issueCount, // 这里的value对应Issue的数量
      }));
      let num = 0
      newData.forEach(pro => {
        num = num + pro.value
      })
      console.log(num)
      setTotalNum(num)
      // Correctly update labelConfig by creating a new object
      setLabelConfig({ ...labelConfig, data: dt });
      setConfig(prevConfig => ({
        ...prevConfig,
        data: newData,
      }))
      setJiraData(data)
      // Divide the labels between left and right upon fetching
      const halfIndex = Math.ceil(dt.length / 2)
      setLeftLabels(dt.slice(0, halfIndex))
      setRightLabels(dt.slice(halfIndex))
    }
    initData()
    setInterval(function() {
      initData()
    }, 1000*15)
  }, []);
  // Generate labels for the left side
  const leftLabelElements = leftLabels.map((label, index) => (
    <div key={`left-${index}`} className="station-info">{label}</div>
  ));

  // Generate labels for the right side
  const rightLabelElements = rightLabels.map((label, index) => (
    <div key={`right-${index}`} className="station-info">{label}</div>
  ));
  const style = { width: '200px', height: '50px' };
  return (
    <div className="center-cmp">
      <div className="cc-header">
        <Decoration1 style={style} />
        <div>研发部的“欠债”</div>
        <Decoration1 style={style} />
      </div>

      <div className="cc-details">
        <div>未完成问题总数</div>
        {
          Array.from({ length: totalNum.toString().length }).map((_, index) => (
            <div key={index} className="card">{totalNum.toString()[index]}</div> // 使用 index+1 作为展示内容，根据需要调整
          ))
        }
      </div>

      <div className="cc-main-container">
        <div className="ccmc-left">
          {leftLabelElements}
        </div>

        <ActiveRingChart className="ccmc-middle" config={config} />

        <div className="ccmc-right">
          {rightLabelElements}
        </div>
      </div>
    </div>
  )
}
