import React from 'react'

import {
  FullScreenContainer,
  BorderBox2,
  BorderBox1,
  BorderBox3,
  BorderBox4,
} from '@jiaminghi/data-view-react'

import LeftChart1 from './LeftChart1'
import LeftChart2 from './LeftChart2'
import LeftChart3 from './LeftChart3'

import CenterCmp from './CenterCmp'

import RightChart1 from './RightChart1'
import RightChart2 from './RightChart2'

import BottomCharts from './BottomCharts'

import './index.less'

const style = {
  width: '120px',
  height: '50px',
  lineHeight: '50px',
  textAlign: 'center',
  marginLeft: '200px',
}

export default () => {
  return (
    <div id="data-view">
      <FullScreenContainer>
        <div className="main-header">
          <div className="mh-left">
            青岛登云智上科技有限公司
          </div>
          <div className="mh-middle">研发部办公区</div>
          <div className="mh-right">
            <BorderBox2 style={style}>投屏码：539933</BorderBox2>
          </div>
        </div>

        <BorderBox1 className="main-container">
          <CenterCmp />
        </BorderBox1>
      </FullScreenContainer>
    </div>
  )
}
